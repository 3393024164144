export function wholesalerLevel2tag(level){
    let str = "";
    switch (parseInt(level)) {
        case 0:
            str = "普通用户";
            break;
        case 1:
            str = "代理";
            break;
        case 2:
            str = "老板";
            break;
        default:
            str = "未知"
            break;
    }
    return str;
}